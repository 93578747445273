import React, {HTMLAttributes} from 'react';
import kebabCase from 'lodash/kebabCase';
import shouldHideData from '../../utils/shouldHideData';

type DataRowProps = {
  defaultValue?: string | number | JSX.Element;
  title: string | JSX.Element;
  value?: string | number | JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

const DataRow = ({title, value, defaultValue, ...divAttr}: DataRowProps) => {
  if (shouldHideData(value, defaultValue)) {
    return null;
  }

  return (
    <div {...divAttr} className="flex flex-row items-baseline gap-2 font-base leading-5">
      <div className="w-24 text-gray-600 text-xs text-right uppercase tracking-wide">{title}</div>
      <div className="text-gray-900 text-sm" data-qa={`${kebabCase(title)}-value`}>
        {value || defaultValue}
      </div>
    </div>
  );
};

export default DataRow;
