import React, { forwardRef, HTMLAttributes, ReactNode, useCallback } from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';

import { DialogClose } from '../../organisms/UniversalDialog';

import getRootNode from './getRootNode';
import useBlockBodyScroll from './useBlockBodyScroll';

import useEscapeKey from './useEscapeKey';

interface PopupVisibility {
  isVisible: boolean;
  onChangeVisible?(isVisible: boolean): void;
}

export interface PopupProps extends PopupVisibility, HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  id?: undefined;
  isCloseIconShown?: boolean;
  rootNode?: HTMLElement;
}

const Popup = forwardRef<HTMLDivElement, PopupProps>((props, ref) => {
  const {
    children,
    className,
    isCloseIconShown,
    isVisible,
    onChangeVisible,
    rootNode = getRootNode() as HTMLElement,
    ...divProps
  } = props;

  const { id } = useBlockBodyScroll(isVisible, { rootNode });

  const onClose = useCallback(() => {
    onChangeVisible?.(false);
  }, [onChangeVisible]);

  useEscapeKey({ onClose });

  if (!isVisible) {
    return null;
  }

  return (
    <Portal node={document.body}>
      <div
        ref={ref}
        data-testid="popup-container"
        {...divProps}
        className={classNames('popup--container absolute flex items-center content-center min-h-screen w-full z-[1030]', { 'hidden': !isVisible }, className)}
        id={id}
      >
        <div className="popup--tint fixed inset-0 bg-black/80 backdrop-blur-sm z-[1]" onClick={onClose} />
        <div className="popup--wrapper relative w-full max-w-[1180px] h-auto mx-auto z-[2] sm:w-full">
          {isCloseIconShown && <DialogClose className="popup--cross-icon flex items-center justify-center absolute top-5 right-5 cursor-pointer rounded z-[1030] pointer-events-auto leading-none" onClose={onClose} />}
          <div className="popup--content flex items-center justify-center w-full h-auto pointer-events-auto z-[1030]">{children}</div>
        </div>
      </div>
    </Portal>
  );
});

export default Popup;
