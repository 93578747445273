import React from 'react';

import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';

import NavLinkContext from '../../../libs/navlink-context';

import './index.scss';

interface TabsProps {
  items: {
    id: string;
    content: React.ReactNode;
    active?: boolean;
    exact?: boolean;
    to?: string;
    onClick?: () => void;
  }[];
  useNavLinks?: boolean;
  useNavLinksMobileBtn?: boolean;
}

class Tabs extends React.Component<TabsProps> {
  static get propTypes() {
    return {
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.node.isRequired,
        active: PropTypes.bool,
        exact: PropTypes.bool,
        to: PropTypes.string,
        onClick: PropTypes.func,
      })),
      useNavLinks: PropTypes.bool,
      useNavLinksMobileBtn: PropTypes.bool,
    };
  }

  static defaultProps = {
    useNavLinksMobileBtn: true,
  };

  id: string;

  constructor(props) {
      super(props);
      this.id = uniqueId();
    }

  static contextType = NavLinkContext;

  renderItem({ id, content, active, exact, to, onClick }: { id: string; content: React.ReactNode; active?: boolean; exact?: boolean; to?: string; onClick?: () => void }) {
    const NavLink = this.context;
    const { useNavLinks, useNavLinksMobileBtn } = this.props;
    let node = content;

    if (useNavLinks) {
      node = (
        <NavLink activeClassName="navlink-active" exact={exact} to={to}>
          {content}
        </NavLink>
      );
    }

    const classes = classNames({
      'navlink': useNavLinks,
      'active': active && !useNavLinks,
      'navlink--mobile': useNavLinksMobileBtn,
    });

    return (
      <li className={classes} key={`${this.id}_${id}`} onClick={onClick}>
        {node}
      </li>
    );
  }

  render() {
    const { items, useNavLinks, useNavLinksMobileBtn } = this.props;

    const classes = classNames({
      'tabs': true,
      'tabs--with-nav-links': useNavLinks,
      'tabs--with-nav-links--mobile': useNavLinksMobileBtn,
    });

    return (
      <ul className={classes}>
        {items.map(this.renderItem.bind(this))}
      </ul>
    );
  }
}

export default Tabs;
