import React from 'react';

type Direction = 'horizontal' | 'vertical';

interface ScrollableContainerProps {
  children: React.ReactNode;
  direction?: Direction;
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({children, direction = 'vertical'}) => {
  const containerClassName =
    direction === 'horizontal' ? 'relative w-full overflow-x-auto' : 'relative overflow-y-auto h-full';

  const contentClassName =
    direction === 'horizontal' ? 'flex flex-nowrap w-full overflow-x-auto' : 'overflow-y-auto h-full';

  const gradientClassNames = {
    start:
      direction === 'horizontal'
        ? 'top-0 left-0 z-10 absolute bg-gradient-to-r from-white to-transparent w-8 h-full pointer-events-none'
        : 'absolute top-0 left-0 w-full h-8 pointer-events-none bg-gradient-to-b from-white to-transparent z-10',
    end:
      direction === 'horizontal'
        ? 'top-0 right-0 z-10 absolute bg-gradient-to-l from-white to-transparent w-8 h-full pointer-events-none'
        : 'absolute bottom-0 left-0 w-full h-8 pointer-events-none bg-gradient-to-t from-white to-transparent z-10',
  };

  return (
    <div className={containerClassName}>
      <div className={gradientClassNames.start} />
      <div className={contentClassName}>{children}</div>
      <div className={gradientClassNames.end} />
    </div>
  );
};

export default ScrollableContainer;
