import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import InputController from '../../atoms/Input';
import {createValidator, cityAddress, required} from '../../../libs/utils/validation';
import {parsePlace} from '../../../libs/utils/places';
import {getGoogleLoader} from '../../../libs/integrations/google';
import Spinner from '../../atoms/Spinner';
import Button from '../../atoms/Button';

import './index.scss';
import {InputSize, InputSizesStatic} from '../../atoms/Input/types';

interface AddressInputProps {
  size?: InputSize | InputSizesStatic;
  buttonFlavor?: 'primary' | 'cta' | 'secondary' | 'regular' | 'attention' | 'action';
  buttonTitle?: string;
  getCheckedZIP?: (zipcode: string, placeData: any) => Promise<any>;
  inputLabel?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onLoadEnds?: () => void;
  onLoadStarts?: (place: any) => void;
  onSubmit?: (lead: any) => void;
  product?: string;
  showButton?: boolean;
  value?: string;
  validationMessage?: string;
  isValid?: boolean;
}

interface AddressInputState {
  value: string;
  lead: any;
  error: string | null;
  isGoogleLoaded: boolean;
  loading?: boolean;
}

export default class AddressInput extends React.Component<AddressInputProps, AddressInputState> {
  static get propTypes() {
    return {
      size: PropTypes.oneOf(['large', 'medium', 'small']),
      buttonFlavor: PropTypes.oneOf(['primary', 'cta', 'secondary', 'regular', 'attention', 'action']),
      buttonTitle: PropTypes.string,
      getCheckedZIP: PropTypes.func,
      inputLabel: PropTypes.string,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onLoadEnds: PropTypes.func,
      onLoadStarts: PropTypes.func,
      onSubmit: PropTypes.func,
      product: PropTypes.string,
      showButton: PropTypes.bool,
      value: PropTypes.string,
      validationMessage: PropTypes.string,
      isValid: PropTypes.bool,
    };
  }

  static defaultProps = {
    size: 'large',
    buttonFlavor: 'cta',
    buttonTitle: 'Get a quote',
    inputLabel: 'Enter your street address',
    isValid: undefined,
  };

  input: HTMLInputElement | null;
  validator: any;
  autocomplete!: google.maps.places.Autocomplete | null;
  google: any;

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      lead: null,
      error: null,
      isGoogleLoaded: false,
    };

    this.input = null;
    this.validator = createValidator({
      address: [required, cityAddress],
    });
  }

  componentDidMount() {
    getGoogleLoader()
      .load()
      .then((google) => {
        this.google = google;
        this.setState({
          ...this.state,
          isGoogleLoaded: true,
        });
      });
  }

  initAutocomplete = (google, input) => {
    this.autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['geocode'],
      componentRestrictions: {country: 'us'},
    });

    if (this.autocomplete) {
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    }
  };

  getFormFieldValue(place) {
    return place.formatted_address || '';
  }

  fillInAddress = () => {
    const {product, getCheckedZIP, onLoadStarts, onLoadEnds, onChange} = this.props;
    const place = this.autocomplete?.getPlace();
    const placeData = parsePlace(place);

    const error = this.validator({
      address: placeData,
    });

    const data = {
      address: placeData,
      product_slug: product,
    };

    if (error) {
      this.setState({
        error: error.address,
        value: this.getFormFieldValue(place),
      });
      onChange && onChange(this.getFormFieldValue(place));
    } else {
      this.setState({
        loading: true,
        value: this.getFormFieldValue(place),
      });
      onChange && onChange(this.getFormFieldValue(place));
      onLoadStarts && onLoadStarts(place);

      getCheckedZIP &&
        getCheckedZIP(placeData.zipcode, placeData).then(
          (result) => {
            data['productAvailability'] = result.data;
            this.setState({
              loading: false,
              lead: data,
            });
            onLoadEnds && onLoadEnds();
            this.submitSuccess(data);
          },
          (error) => {
            console.log(error);
            let errorMessage = 'Server is not responding';
            if (error?.response?.status == 404) {
              errorMessage = 'City or zipcode not currently supported';
            }
            this.setState({
              loading: false,
              error: errorMessage,
              lead: null,
            });
            onLoadEnds && onLoadEnds();
          },
        );
    }
  };

  submitSuccess = (lead) => {
    const {onSubmit} = this.props;

    onSubmit && onSubmit(lead);
  };

  getValue() {
    return this.props.value !== undefined ? this.props.value : this.state.value;
  }

  handleInputChange = (e) => {
    const {onChange} = this.props;
    const value = e.target.value;
    this.setState({
      value,
      error: '',
    });
    onChange && onChange(value);
  };

  handleInputFocus = () => {
    const {onFocus} = this.props;
    onFocus?.();
    if (this.autocomplete || !this.google) {
      return;
    }
    this.initAutocomplete(this.google, this.input);
  };

  handleButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const {lead} = this.state;

    if (lead) {
      this.submitSuccess(lead);
    } else {
      this.setState({
        error: 'Select your address from the list below',
      });
    }
  };

  render() {
    const {showButton, size, buttonFlavor, buttonTitle, inputLabel, validationMessage, isValid} = this.props;
    const {loading, error} = this.state;
    const value = this.getValue();
    const addressInputClassName = classNames({
      'address-input': true,
      'is-loading': loading,
      'is-has-value': value,
      'is-error': error,
      [`input-${size}`]: size,
    });
    const addressButtonClassName = classNames({
      'address-button': true,
      'is-loading': loading,
    });
    return (
      <div className={addressInputClassName}>
        <InputController
          disabled={loading}
          name="textinput"
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          placeholder="1234 Street, Sacramento, CA"
          ref={(input) => (this.input = input as HTMLInputElement)}
          type="text"
          value={value}
          label={inputLabel}
          size={size}
          isValid={isValid && !error}
          validationMessage={error || validationMessage}
        />
        {showButton && (
          <Button
            className={addressButtonClassName}
            disabled={loading}
            flavor={buttonFlavor}
            onClick={this.handleButtonClick}
            size={size}
          >
            {loading ? <Spinner active={true} borderWidth={0.1} size={25} /> : buttonTitle}
          </Button>
        )}
      </div>
    );
  }
}
