import React from 'react';
import classNames from 'classnames';
import {ReactSVG} from 'react-svg';
import NavLinkContext from '../../../libs/navlink-context';
import './index.scss';

interface DropdownMenuItem {
  content: React.ReactNode;
  onClick?: () => void;
  href?: string;
  special?: boolean;
  iconSVG?: string;
}

interface DropdownMenuProps {
  alwaysOpen?: boolean;
  items?: DropdownMenuItem[];
  title?: React.ReactNode;
}

interface DropdownMenuState {
  showMenu: boolean;
}

class DropdownMenu extends React.Component<DropdownMenuProps, DropdownMenuState> {
  private dropdownMenu: HTMLDivElement | null = null;
  static contextType = NavLinkContext;
  context!: React.ContextType<typeof NavLinkContext>;

  static defaultProps = {
    alwaysOpen: false,
    items: [
      {
        href: 'https://www.ergeon.com/fence-specifications',
        content: 'Fence Specifications',
      },
      {
        href: 'https://www.ergeon.com/gallery/fence/picture-frame/without-lattice',
        content: 'Fence Photos',
      },
      {
        href: 'https://www.ergeon.com/gallery/gate/picture-frame/single',
        content: 'Gate Photos',
      },
      {
        href: 'https://www.ergeon.com/gallery/driveway/stamped/casual',
        content: 'Driveway Photos',
      },
      {
        href: 'https://www.ergeon.com/about-ergeon',
        content: 'About',
      },
      {
        href: 'https://fencequoting.com/',
        content: 'Fence Calculator',
        special: true,
      },
    ],
    title: <span>About Ergeon</span>,
  };

  constructor(props: DropdownMenuProps) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }

  showMenu = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const {showMenu} = this.state;
    this.setState({showMenu: !showMenu}, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = (event: MouseEvent) => {
    if (!this.dropdownMenu || !this.dropdownMenu.contains(event.target as Node)) {
      this.setState({showMenu: false}, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  };

  handleMenuItemClick = (onClick?: () => void) => {
    this.setState({showMenu: false});
    onClick && onClick();
  };

  renderMenuItem = ({content, href, iconSVG, special, onClick}: DropdownMenuItem, index: number) => {
    const className = classNames('menu-item', {special});
    const NavLink = this.context;

    let ElementComponent: 'button' | typeof NavLink = 'button';

    if (href) {
      ElementComponent = NavLink;
    }

    return (
      <ElementComponent
        activeClassName="active-link"
        className={className}
        key={index}
        onClick={() => this.handleMenuItemClick(onClick)}
        to={href || ''}
      >
        <div className="menu-title">
          {iconSVG && <ReactSVG src={iconSVG} />}
          <span>{content}</span>
        </div>
      </ElementComponent>
    );
  };

  render() {
    const {title, items, alwaysOpen} = this.props;
    const {showMenu} = this.state;
    return (
      <span className="dropdown-menu">
        <a className="link" href="#" onClick={this.showMenu}>
          <div className="flex-align-middle">
            {title}
            <i
              className={classNames('dropdown-menu--icon', {
                'rotate-menu-icon': showMenu,
              })}
            />
          </div>
        </a>
        {(showMenu || alwaysOpen) && (
          <div
            className="menu"
            ref={(element) => {
              this.dropdownMenu = element;
            }}
          >
            {items?.map(this.renderMenuItem)}
          </div>
        )}
      </span>
    );
  }
}

export default DropdownMenu;
