import React, {ElementType, useContext} from 'react';

import NavLinkContext from '../../../libs/navlink-context';
import withScrollTop from './with-scroll-top';

export interface ErgeonLinkProps {
  className?: string;
  children?: React.ReactNode;
  href?: string;
  rel?: string;
  target?: string;
}

type ErgeonNavLinkType = ElementType<ErgeonLinkProps & {to: string}>;

const ScrollableSpan = (props: React.HTMLProps<HTMLSpanElement>) => {
  return <span {...props} />;
};

const ErgeonLink: React.FC<ErgeonLinkProps> = (props) => {
  const {href = '', children} = props;
  const NavLink: ErgeonNavLinkType = useContext(NavLinkContext);
  const useNavLink = href.startsWith('/');

  if (useNavLink) {
    const ScrollOnClick = withScrollTop(ScrollableSpan);
    return (
      <NavLink {...props} to={href}>
        <ScrollOnClick>{children}</ScrollOnClick>
      </NavLink>
    );
  }

  return <a {...props}>{children}</a>;
};

export default ErgeonLink;
