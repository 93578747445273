import React, {useState, useEffect} from 'react';

import {ReactSVG} from 'react-svg';

import PopupImage from '../../organisms/SpecificationTable/components/PopupImage';
import Spinner from '../Spinner';
import IconPhotoPlaceholder from '@ergeon/icons/svg/icon-photo-placeholder.svg';
import './FunctionalImage.scss';

interface FunctionalImageProps {
  image?: string;
}

const FunctionalImage = ({image}: FunctionalImageProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isImageValid, setIsImageValid] = useState<boolean | null>(null);

  useEffect(() => {
    setTimeout(() => {
      // to just show visually loader
      if (image) {
        const img = new Image();
        img.src = image;
        img.onload = () => setIsImageValid(true);
        img.onerror = () => setIsImageValid(false);
      } else {
        setIsImageValid(false);
      }
    }, 500);
  }, [image]);

  if (isImageValid === true) {
    return (
      <>
        <PopupImage imageSrc={image as string} onHide={() => setIsVisible(false)} visible={isVisible} />
        <div className="active-img schematic-container" onClick={() => setIsVisible(true)}>
          <div className="img-safe-wrapper">
            <img className="schematic-img" src={image} data-testid="schematic-image" />
          </div>
        </div>
      </>
    );
  }

  if (isImageValid === false) {
    return (
      <div className="schematic-container">
        <ReactSVG className="placeholder-icon" src={IconPhotoPlaceholder} data-testid="placeholder-icon" />
      </div>
    );
  }

  return <Spinner active color="blue" />;
};

export default FunctionalImage;
