import React, { useEffect } from 'react';
import Input from '../Input';
import { InputProps } from '../Input/types';
import { PHONE_REGEXP } from '../../../constants';

export interface PhoneInputProps extends InputProps {
  onPhoneValid?: (value: boolean) => void,
};

/**
 * Adds a phone number interactable mask (555) 123-4567 to the original input.
 */
const PhoneInput = (props: PhoneInputProps) => {
  const { value, onPhoneValid, ...otherProps } = props;

  // checks value to see if we have a valid phone (555) 123-4567
  useEffect(() => (
    onPhoneValid?.(PHONE_REGEXP.test(value || ''))
  ), [value]);

  return (
    <Input
      mask="(999) 999-9999"
      type="tel"
      {...otherProps}
      value={value} />
  );
};

export default PhoneInput;
