import React from 'react';

import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';

import './index.scss';

interface SwitchGroupState {
  activeId: string | number;
}

interface SwitchGroupProps {
  activeId: string | number;
  className?: string;
  items: Array<{
    id: string | number;
    content: React.ReactNode;
    onClick?: () => void;
  }>;
  size?: 'medium' | 'small';
}

class SwitchGroup extends React.Component<SwitchGroupProps, SwitchGroupState> {
  id: string;
  static get propTypes() {
    return {
      activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      className: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        content: PropTypes.node.isRequired,
        onClick: PropTypes.func,
      })),
      size: PropTypes.oneOf(['medium', 'small']),
    };
  }
  static defaultProps = {
    size: 'medium',
  };
  constructor(props) {
    super(props);
    this.state = {
      activeId: props.activeId,
    };
    this.id = uniqueId();
  }
  handleClick(itemOnClick, id) {
    itemOnClick && itemOnClick();
    this.setState({ activeId: id });
  }
  renderItem({ id, content, onClick = () => {} }) {
    const { activeId } = this.state;
    const { size } = this.props;
    const node = content;
    const classes = classNames({
      'size__medium': size === 'medium',
      'size__small': size === 'small',
      'active': id === activeId,
    });

    return (
      <span className={classes} key={`${this.id}_${id}`} onClick={() => this.handleClick(onClick, id)}>
        {node}
      </span>
    );
  }

  render() {
    const { items, className } = this.props;
    const classes = classNames({
      'switch': true,
      [className || '']: className,
    });

    return (
      <div className={classes}>
        {items.map(item => this.renderItem(item))}
      </div>
    );
  }
}

export default SwitchGroup;
